import { Box, Flex, Spacer } from "@chakra-ui/react";
import React from "react";
import PvBanner from "../components/PvBanner";
import PvFeatureGrid from "../components/PvFeatureGrid";
import QAAccordion from "../components/QAAccordion";
import { PiGlobe, PiPuzzlePieceBold } from "react-icons/pi";
import { TbCalendarDollar } from "react-icons/tb";

const features = [
  {
    title: "Düşük Giriş Maaliyeti",
    description:
      "Geleneksel borsa altyapısı kurulum maliyetlerini minimuma indirir",
    icon: TbCalendarDollar,
  },
  {
    title: "Kolay Entegrasyon",
    description: "Geliştiriciler için kolay entegrasyon",
    icon: PiPuzzlePieceBold,
  },
  {
    title: "Global Piyasalara Erişim",
    description:
      "Tek bir platform üzerinden birden fazla piyasaya erişim sağlayarak, kullanıcıların dünya çapında yatırım yapmalarını mümkün kılar",
    icon: PiGlobe,
  },
];

const qaData = [
  {
    question: "Borsa as a service nedir?",
    answer:
      "Borsa as a Service, bir borsa kurmak için gerekli tüm altyapı ve uzun geliştirme süreçleri ile maliyetlerinden tasarruf etmenizi sağlayan, hazır bir Polyverse ürünüdür.",
  },
  {
    question: "Satın alım sonrasında destek sağlanıyor mu?",
    answer:
      "Elbette, ürün lisanslama sürecinden sonra sistemi tamamen çalıştırana dek sizlere destek oluyoruz!",
  },
  {
    question: "Bu ürün ile beraber developer ekibi gerekiyor mu?",
    answer:
      "Bu ürün her ne kadar maliyetlerinizi büyük oranda düşürse de küçük bir developer ekibinizin olması faydalı olabilir.",
  },
  {
    question: "Bu hizmetten kimler yararlanabilir?",
    answer:
      "Hali hazırda borsalar veya borsa kurmak isteyen tüm kişi ve kurumlar yararlanabilir.",
  },
];

function ExchangeAsService() {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="Borsa as a Service, kurumların karmaşık borsa altyapısı kurmak zorunda kalmadan hızlı, güvenli ve ölçeklenebilir bir şekilde global piyasalarda alım satım yapmalarını sağlayarak, hem maliyetleri düşürür hem de zaman kazandırır"
        header1="Borsa Kurmak"
        header2="Hiç Bu Kadar Kolay Olmamıştı"
        buttonText="Borsa As a Service"
        boxPadding="35px"
        isHeader1Gradient={true}
        isHeader2Gradient={false}
      />
      <PvFeatureGrid items={features} />
      <Spacer height="32" />
      <QAAccordion faqData={qaData} />
    </Box>
  );
}

export default ExchangeAsService;
