import React from "react";
import PvBanner from "../components/PvBanner";
import { Box } from "@chakra-ui/react";
import PvFeatureGrid from "../components/PvFeatureGrid";
import PotentialUseCases from "../components/PotentialUseCases";
import QAAccordion from "../components/QAAccordion";
import ContactUs from "../components/ContactUs";
import { GrAppsRounded, GrGroup } from "react-icons/gr";
import { PiHandsClapping, PiShieldCheckeredFill } from "react-icons/pi";
import { RiNftLine } from "react-icons/ri";
import { LiaFileContractSolid } from "react-icons/lia";

const features = [
  {
    title: "Merkeziyetsiz Uygulamalar (dApps)",
    description:
      "Farklı kategorilerdeki merkeziyetsiz uygulamalara (dApps) kolayca erişim",
    icon: GrAppsRounded,
  },
  {
    title: "Akıllı Sözleşmeler",
    description:
      "Şeffaf ve güvenli işlemler için akıllı sözleşmeler oluşturma ve yönetme",
    icon: LiaFileContractSolid,
  },
  {
    title: "NFT Platformları",
    description:
      "Dijital sanat eserleri ve koleksiyonları keşfetmek ve takas etmek için NFT platformlarına erişim",
    icon: RiNftLine,
  },
  {
    title: "Güvenlik ve Özellik Zenginliği",
    description:
      "Web3 hizmetlerinde yüksek güvenlik standartları ve geniş özellik seti",
    icon: PiShieldCheckeredFill,
  },
  {
    title: "Kullanıcı Dostu Deneyim",
    description:
      "Basit ve kullanıcı dostu deneyim ile Web3 uygulamalarına kolay erişim",
    icon: PiHandsClapping,
  },
  {
    title: "Topluluk ve Katılım",
    description:
      "Web3 topluluklarına katılım ve projelerde aktif rol alma imkanı",
    icon: GrGroup,
  },
];

const qaData = [
  {
    question: "Web3 nedir?",
    answer:
      "Web3, merkeziyetsiz internet uygulamaları (dApps) ve hizmetlerini tanımlayan bir terimdir",
  },

  {
    question: "Web3 Erişimi ile hangi hizmetlere ulaşabilirim?",
    answer:
      "Merkeziyetsiz uygulamalar (dApps), akıllı sözleşmeler, NFT platformları ve daha fazlasına erişim hizmeti sunuyoruz",
  },
  {
    question: "Web3 Erişimi nasıl çalışır?",
    answer:
      "Web3 Erişimi, Polyverse platformu üzerinden dApps, akıllı sözleşmeler ve NFT'ler gibi hizmetlere erişim entegrasyonu sağlar",
  },
  {
    question: "Web3 Erişimi kullanmanın avantajları nelerdir?",
    answer:
      "Web3 Erişimi, kullanıcılarınızın merkeziyetsiz platformlara güvenli ve hızlı bir şekilde erişmesini sağlar, veri gizliliğini korur ve aracıları ortadan kaldırarak maliyetleri düşürür",
  },
];

const storageUseCases = [
  {
    title: "Geliştiriciler",
    description:
      "Merkeziyetsiz uygulamalar geliştirmek ve Web3 teknolojilerini keşfetmek isteyen geliştiriciler için mükemmel bir platformdur",
  },
  {
    title: "Yatırımcılar",
    description:
      "Web3 ekosistemine yatırım yapmak isteyenler için kapsamlı bilgi ve erişim sağlar",
  },
  {
    title: "Sanatçılar ve Yaratıcılar",
    description:
      "NFT platformlarında dijital sanat eserlerini sergilemek ve satmak isteyen sanatçılar için idealdir",
  },
  {
    title: "Kurumlar ve İşletmeler",
    description:
      "Merkeziyetsiz teknolojileri iş süreçlerine entegre etmek isteyen işletmeler için uygundur",
  },
  {
    title: "Topluluk Üyeleri",
    description:
      "Web3 projelerine katkıda bulunmak ve topluluklarla etkileşimde bulunmak isteyen herkes için erişim imkanı sunar",
  },
];

function Web3Access() {
  const handleSecondButtonClick = () => {
    window.open("https://platform.polyverse.life", "_blank");
  };
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="Polyverse, kullanıcılarınız için Web3 ekosistemine sorunsuz bir geçiş sunar. Merkeziyetsiz uygulamalar (dApps), akıllı sözleşmeler ve daha fazlası ile entegre olarak, merkeziyetsiz dünyanın avantajlarını keşfedin"
        header1="Yeni Nesil"
        header2="İnternet Deneyimi"
        buttonText="Merkeziyetsiz Uygulamalara ve Hizmetlere Erişim"
        boxPadding="45px"
        isHeader1Gradient={false}
        isHeader2Gradient={true}
        secondButtonText="Şimdi Başlayın 🚀"
        onSecondButtonClick={handleSecondButtonClick}
      />
      <PvFeatureGrid items={features} />
      <PotentialUseCases
        title="Potansiyelinizi Açığa Çıkartın"
        useCases={storageUseCases}
      />
      <QAAccordion faqData={qaData} />
      <ContactUs />
    </Box>
  );
}

export default Web3Access;
