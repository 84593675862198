import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "../components/ScrollToTop";

const Layout = ({ children }) => {
  return (
    <VStack minHeight="100vh" spacing={0}>
      <ScrollToTop />
      <Header />
      <Box as="main" flexGrow={1} width="full">
        {children}
      </Box>
      <Footer />
    </VStack>
  );
};

export default Layout;
