import { Box } from "@chakra-ui/react";
import React from "react";
import PvBanner from "../components/PvBanner";
import PvFeatureGrid from "../components/PvFeatureGrid";
import PotentialUseCases from "../components/PotentialUseCases";
import ContactUs from "../components/ContactUs";
import { PiCoin, PiPuzzlePieceBold } from "react-icons/pi";
import {
  MdOutlineFindInPage,
  MdOutlineScreenshotMonitor,
} from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { TiFlashOutline } from "react-icons/ti";

const features = [
  {
    title: "Hızlı Geçiş",
    description: "Hızlı Kripto - Fiyat(yada Fiyat-Kripto) geçişi imkanı",
    icon: TiFlashOutline,
  },
  {
    title: "Stable Coinler",
    description: "Stable Coinler ile risksiz hareket edebilme",
    icon: PiCoin,
  },
  {
    title: "İşlemler ve Detayları",
    description: "Yapılan tüm işlemlere ait detaylı veriler",
    icon: MdOutlineFindInPage,
  },
  {
    title: "Çoklu Para Birimi",
    description: "TRY yanında EUR, USD ve GBP destekler",
    icon: GrMoney,
  },
  {
    title: "Kolay API Entegrasyonu",
    description: "Geliştiriciler için kolay entegrasyon",
    icon: PiPuzzlePieceBold,
  },
  {
    title: "Çoklu Platform",
    description: "Servisler hem Mobile hem de Web uyumludur",
    icon: MdOutlineScreenshotMonitor,
  },
];

const onOffUseCases = [
  {
    title: "Borsa Kullanıcıları",
    description:
      "Kripto borsa kullanıcıların yerel para birimleriyle kripto satın almalarını ve kripto paralarını yerel para birimlerine dönüştürmelerine imkan verir",
  },
  {
    title: "E-Ticaret",
    description:
      "E-ticaret platformları, satıcıların kripto ödemeleri kabul etmesine ve bu ödemeleri hızlı bir şekilde fiat paraya dönüştürmesine olanak tanıyabilir",
  },
  {
    title: "Oyun Şirketleri",
    description:
      "Oyun platformları, oyuncuların kripto paralarla oyun satın almalarını ve oyun içi öğeleri takas etmelerini sağlamak için bu hizmeti kullanabilir",
  },
];

function OnOffRamp() {
  return (
    <Box width="100%" maxWidth="100vw" overflowX="hidden">
      <PvBanner
        title="On Ramp / Off Ramp hizmetleri ile entegre olarak kullanıcılarınızın geleneksel finans"
        title2="ve kripto paralar arasında sorunsuz geçiş yapabilmelerine olanak verin"
        header1="Geleneksel Finans ile "
        header2="Kripto Arasındaki Köprünüz"
        buttonText="On Ramp / Off Ramp As a Service"
        boxPadding="35px"
        isHeader1Gradient={false}
        isHeader2Gradient={true}
      />
      <PvFeatureGrid items={features} />
      <PotentialUseCases
        title="Potansiyelinizi Açığa Çıkartın"
        useCases={onOffUseCases}
      />
      <ContactUs />
    </Box>
  );
}

export default OnOffRamp;
