import React from "react";
import {
  Box,
  Container,
  Flex,
  Grid,
  Link,
  Text,
  Divider,
  VStack,
  Image,
  useBreakpointValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
} from "@chakra-ui/react";
import Logo from "../assets/polyverse-logo.svg";
import Twitter from "../assets/icons/twitter.svg";
import LinkedIn from "../assets/icons/linkedin.svg";
import Medium from "../assets/icons/medium.svg";
import Instagram from "../assets/icons/instagram.svg";
import Discord from "../assets/icons/discord.svg";
import YouTube from "../assets/icons/youtube.svg";
import TikTok from "../assets/icons/tiktok.svg";

const SocialButton = ({ icon, href }) => (
  <Link href={href} isExternal>
    <Box
      h={{ base: "30px", sm: "30px", md: "45px" }}
      w={{ base: "30px", sm: "30px", md: "45px" }}
      borderRadius="full"
      bg="white.26"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={2}
      _hover={{ bg: "#FFFFFF4D" }}
      _active={{ bg: "#FFFFFF66" }}
    >
      <Image src={icon} alt="Social Icon" />
    </Box>
  </Link>
);

const footerSections = [
  {
    title: "Platform",
    items: [
      { label: "Web3 Wallet As a Service", href: "/wallet-as-a-service" },
      { label: "Tokenizasyon", href: "/tokenization" },
      { label: "Off Ramp/On Ramp As a Service", href: "/on-off-ramp" },
      { label: "Borsa As a Service", href: "/exchange-as-a-service" },
      { label: "Web3 Erişimi (Platform)", href: "/web3-access" },
    ],
  },
  {
    title: "Developer",
    href: "https://docs.polyverse.life/",
    isExternal: true,
  },
  {
    title: "Hakkımızda",
    href: "/about-us",
  },
];

const Footer = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      as="footer"
      bg="purple.500"
      width="full"
      color="white"
      pb={{ base: 8, md: 12 }}
      pt={{ base: 8, md: 20 }}
      px={{ base: 4, sm: 12, md: 20, lg: 24, xl: "150px" }}
    >
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          width={"auto"}
          mb={{ base: 8, md: 24 }}
        >
          <VStack
            align="flex-start"
            spacing={{ base: 6, md: 12 }}
            mb={{ base: 8, md: 0 }}
          >
            <Image src={Logo} alt="Logo" w={{ base: "150px", md: "280px" }} />
            {!isMobile && (
              <Flex align="center" justify="center" ml={4}>
                <SimpleGrid columns={4} spacing={4} width="full">
                  <SocialButton
                    icon={Twitter}
                    href="https://x.com/PolyVerseLife"
                  />
                  <SocialButton
                    icon={LinkedIn}
                    href="https://www.linkedin.com/company/polyverse-life/"
                  />
                  <SocialButton
                    icon={Discord}
                    href="https://discord.gg/wA3C98CV"
                  />
                  <SocialButton
                    icon={Instagram}
                    href="https://www.instagram.com/polyverse.life?igsh=MTljemUzbTVkcmFzMw=="
                  />
                  <SocialButton
                    icon={TikTok}
                    href="https://www.tiktok.com/@polyverselife"
                  />
                  <SocialButton
                    icon={YouTube}
                    href="https://www.youtube.com/channel/UCYVgL5e2F0ZKdmX3nUVerHQ"
                  />
                  <SocialButton
                    icon={Medium}
                    href="https://medium.com/@polyverse.life"
                  />
                </SimpleGrid>
              </Flex>
            )}
          </VStack>
          {isMobile ? (
            <Accordion allowToggle>
              {footerSections.map((section) =>
                section.title === "Developer" ||
                section.title === "Hakkımızda" ? (
                  <Box key={section.title} mb={4}>
                    <Link
                      href={section.href}
                      fontWeight="bold"
                      isExternal={section.isExternal}
                    >
                      {section.title}
                    </Link>
                  </Box>
                ) : (
                  <AccordionItem key={section.title} border="none">
                    <AccordionButton p={0}>
                      <Box flex="1" textAlign="left" fontWeight="bold" mb={4}>
                        {section.title}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <VStack align="flex-start" spacing={3}>
                        {section.items.map(
                          (item, index) =>
                            item.href && (
                              <Link
                                key={index}
                                href={item.href}
                                fontSize="sm"
                                color="white.99"
                                _hover={{ textDecoration: "underline" }}
                              >
                                {item.label}
                              </Link>
                            )
                        )}
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                )
              )}
            </Accordion>
          ) : (
            <Grid
              templateColumns={{ base: "1fr", sm: "2fr 1fr 1fr" }}
              gap={{ base: 4, md: 8, lg: 20 }}
              mt={{ base: 8, md: 16 }}
              mr={{ base: 12, md: 8, lg: 0 }}
              ml={{ base: 12, md: 8, lg: 20 }}
            >
              {footerSections.map((section) =>
                section.title === "Developer" ||
                section.title === "Hakkımızda" ? (
                  <Box key={section.title}>
                    <Link
                      href={section.href}
                      fontWeight="bold"
                      fontSize="lg"
                      isExternal={section.isExternal}
                    >
                      {section.title}
                    </Link>
                  </Box>
                ) : (
                  <VStack key={section.title} align="flex-start" spacing={4}>
                    <Text fontWeight="bold" fontSize="lg">
                      {section.title}
                    </Text>
                    <VStack
                      align="flex-start"
                      spacing={{ base: 1, md: 2, lg: 3 }}
                    >
                      {section.items.map(
                        (item, index) =>
                          item.href && (
                            <Link
                              key={index}
                              href={item.href}
                              fontSize="lg"
                              color="white.99"
                              _hover={{ textDecoration: "underline" }}
                            >
                              {item.label}
                            </Link>
                          )
                      )}
                    </VStack>
                  </VStack>
                )
              )}
            </Grid>
          )}

          {isMobile && (
            <Flex align="center" justify="center" w="50%">
              <SimpleGrid columns={4} spacing={2} width="full">
                <SocialButton
                  icon={Twitter}
                  href="https://x.com/PolyVerseLife"
                />
                <SocialButton
                  icon={LinkedIn}
                  href="https://www.linkedin.com/company/polyverse-life/"
                />
                <SocialButton
                  icon={Discord}
                  href="https://discord.gg/wA3C98CV"
                />
                <SocialButton
                  icon={Instagram}
                  href="https://www.instagram.com/polyverse.life?igsh=MTljemUzbTVkcmFzMw=="
                />
                <SocialButton
                  icon={TikTok}
                  href="https://www.tiktok.com/@polyverselife"
                />
                <SocialButton
                  icon={YouTube}
                  href="https://www.youtube.com/channel/UCYVgL5e2F0ZKdmX3nUVerHQ"
                />
                <SocialButton
                  icon={Medium}
                  href="https://medium.com/@polyverse.life"
                />
              </SimpleGrid>
            </Flex>
          )}
        </Flex>
        <Divider mb="5" color="white" opacity={"0.15"} borderWidth={"2px"} />
        <VStack spacing={{ base: 0, sm: 2 }} align="flex-start">
          <Text fontSize="md">
            Tüm Hakları Saklıdır © 2021 Yenkido Bilişim Hizmetleri Ticaret AŞ
            Mersis NO : 0949076075300001{" "}
          </Text>
          <Flex direction="row" gap={2}>
            <Text
              as={Link}
              href="/terms-of-service"
              fontSize="sm"
              color="white.80"
            >
              Hizmet Koşulları & Gizlilik Politikası
            </Text>
          </Flex>
        </VStack>
      </Container>
    </Box>
  );
};

export default Footer;
