import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useBreakpointValue,
  useDisclosure,
  Image,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { HamburgerIcon, ChevronDownIcon } from "@chakra-ui/icons";
import Logo from "../assets/polyverse-logo.svg";
import { useNavigate } from "react-router-dom";
import { TbBuildingBank, TbArrowsExchange } from "react-icons/tb";
import { PiWallet, PiCoin, PiGlobe, PiChartLineUp } from "react-icons/pi";

const PolyLogo = ({ handleLogoClick }) => (
  <Box onClick={handleLogoClick} cursor="pointer">
    <Image src={Logo} alt="Poly Logo" width="auto" height="40px" />
  </Box>
);

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const handleLogoClick = () => {
    navigate("/");
  };

  const navItems = [
    {
      label: "Platform",
      children: [
        {
          title: "Web3 Wallet As a Service",
          description: "Dijital cüzdan ihtiyacı için en iyi çözüm",
          href: "/wallet-as-a-service",
          icon: PiWallet,
        },
        {
          title: "Tokenizasyon",
          description: "Varlıkların dijital tokenlara dönüştürülmesi",
          href: "/tokenization",
          icon: PiCoin,
        },
        {
          title: "Hazine Yönetimi",
          description: "Dijital varlıkların etkin yönetimi",
          tag: "Çok Yakında!",
          icon: TbBuildingBank,
        },
        {
          title: "Off Ramp/On Ramp As a Service",
          description: "On Ramp / Off Ramp ihtiyacınıza en iyi çözüm",
          href: "/on-off-ramp",
          icon: TbArrowsExchange,
        },
        {
          title: "Borsa As a Service",
          description: "En hızlı Borsa çözümü",
          href: "/exchange-as-a-service",
          icon: PiChartLineUp,
        },
        {
          title: "Web3 Erişimi (Platform)",
          description: "Web3'e en kolay erişim",
          href: "/web3-access",
          icon: PiGlobe,
        },
      ],
    },
    // { label: "Developer", href: "/developers" },
    {
      label: "Developer",
      href: "https://docs.polyverse.life/",
      target: "_blank",
      rel: "noopener noreferrer",
    },
    { label: "Hakkımızda", href: "/about-us" },
  ];

  const DesktopNav = () => (
    <Stack direction={"row"} gap={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover placement="bottom" strategy="fixed" trigger="hover">
            <PopoverTrigger>
              <Flex
                as={Link}
                p={2}
                href={navItem.href}
                target={navItem.target}
                rel={navItem.rel}
                variant="ghost"
                color="text.secondary"
                fontSize="20px"
                width="auto"
                paddingX="4"
                borderRadius="8px"
                _hover={{
                  bgColor: "rgba(66, 153, 225, 0.2)",
                  paddingX: "4",
                  borderRadius: "10px",
                  textDecoration: "none",
                }}
                fontWeight="normal"
                alignItems="center"
              >
                <Text>{navItem.label}</Text>
              </Flex>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                mt="8"
                border={0}
                rounded="10px"
                w={{ base: "90vw", md: "auto" }}
                bg="white"
                borderRadius="10px"
                borderColor="transparent"
              >
                <Box flex={1} p={{ base: 6, md: 8 }}>
                  <Text fontWeight="bold" fontSize="26px" mb="2">
                    Platform
                  </Text>
                  <Flex justifyContent="center">
                    <Stack
                      direction={["column", "row"]}
                      spacing={8}
                      width="100%"
                    >
                      <Stack width={["100%", "50%"]}>
                        {navItem.children
                          .slice(0, Math.ceil(navItem.children.length / 2))
                          .map((child) => (
                            <DesktopSubNav key={child.title} {...child} />
                          ))}
                      </Stack>
                      <Stack width={["100%", "50%"]}>
                        {navItem.children
                          .slice(Math.ceil(navItem.children.length / 2))
                          .map((child) => (
                            <DesktopSubNav key={child.title} {...child} />
                          ))}
                      </Stack>
                    </Stack>
                  </Flex>
                </Box>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );

  const DesktopSubNav = ({ title, href, description, tag, icon }) => (
    <Link
      href={href}
      _hover={{
        bgColor: tag ? "transparent" : "gray.100",
        borderRadius: "10px",
      }}
      role={"group"}
      display={"block"}
      py={2}
      pointerEvents={tag ? "none" : "auto"}
    >
      <Stack
        direction={{ base: "column", md: "row" }}
        align={{ base: "flex-start", md: "center" }}
      >
        <Flex align={"center"} pl={{ base: 2, md: 2 }}>
          {icon && (
            <Icon
              as={icon}
              width="30px"
              height="30px"
              mr={4}
              display={{ base: "none", md: "block" }}
            />
          )}
          <Flex direction="column">
            <Text
              transition={"all .3s ease"}
              fontWeight="semibold"
              color="black"
              fontSize={{ base: "16px", md: "20px" }}
            >
              {title}
            </Text>
            {description && (
              <Text
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="normal"
                color="#00000099"
                whiteSpace="nowrap"
              >
                {description}
              </Text>
            )}
          </Flex>
        </Flex>
        {tag && (
          <Button
            variant="pvGradient"
            alignSelf={{ base: "flex-start", md: "center" }}
            fontWeight="normal"
            px={6}
            py={2}
            mt={{ base: 2, md: 0 }}
            ml={{ base: 0, md: 4 }}
            color="blue.300"
            _hover={{ bgColor: "transparent" }}
            fontSize="12px"
            w="auto"
            height="auto"
            borderRadius="10px"
          >
            {tag}
          </Button>
        )}
      </Stack>
    </Link>
  );

  const MobileNav = () => (
    <Stack align="stretch" spacing={4}>
      {navItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} onClose={onClose} />
      ))}
    </Stack>
  );

  const MobileNavItem = ({
    label,
    children,
    href,
    tag,
    onClose,
    target,
    rel,
  }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
      <Stack spacing={4} onClick={children && onToggle}>
        <Flex
          py={2}
          as={tag ? Text : Link}
          href={tag ? undefined : href ?? "#"}
          target={target}
          rel={rel}
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
          onClick={() => {
            if (!children) onClose();
          }}
        >
          <Text fontWeight="semibold" color="white">
            {label}
          </Text>

          {children && (
            <Icon
              as={ChevronDownIcon}
              transition={"all .25s ease-in-out"}
              transform={isOpen ? "rotate(180deg)" : ""}
              color="white"
              w={6}
              h={6}
            />
          )}
        </Flex>

        <Collapse
          in={isOpen}
          animateOpacity
          style={{ marginTop: "0!important" }}
        >
          <Stack
            mt={2}
            pl={4}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor="white"
            align={"start"}
          >
            {children &&
              children.map((child) => (
                <Flex key={child.title} align="center">
                  <Link
                    href={child.href}
                    py={2}
                    color="white"
                    _hover={{
                      textDecoration: "none",
                    }}
                    onClick={onClose}
                  >
                    {child.title}
                  </Link>
                  {child.tag && (
                    <Button
                      variant="pvGradient"
                      alignSelf="center"
                      fontWeight="normal"
                      px={4}
                      py={2}
                      ml={4}
                      color="white"
                      _hover={{}}
                      fontSize="12px"
                      w="auto"
                      height="auto"
                      borderRadius="10px"
                    >
                      {child.tag}
                    </Button>
                  )}
                </Flex>
              ))}
          </Stack>
        </Collapse>
      </Stack>
    );
  };

  return (
    <Box
      as="header"
      width="full"
      zIndex="sticky"
      position="fixed"
      py={{ base: 4, md: 6 }}
      bg="purple.500"
      layerStyle="pvPadding"
    >
      <Flex justify="space-between" align="center">
        <PolyLogo handleLogoClick={handleLogoClick} />

        {isDesktop ? (
          <>
            <DesktopNav />
            <Button
              variant="pvFulledGradient"
              fontSize="16px"
              fontWeight="bold"
              color={"white"}
              onClick={() => {
                navigate("/book-a-meet");
              }}
            >
              Bize Ulaşın
            </Button>
          </>
        ) : (
          <IconButton
            onClick={onOpen}
            icon={<HamburgerIcon w={5} h={5} color="white" />}
            variant={"ghost"}
            _hover={{ bg: "transparent" }}
            aria-label={"Toggle Navigation"}
          />
        )}
      </Flex>

      {!isDesktop && (
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerContent bg="purple.500">
            <DrawerCloseButton color="text.secondary" />
            <DrawerBody py={20}>
              <MobileNav />
              <Button
                variant="pvFulledGradient"
                fontSize="16px"
                fontWeight="bold"
                color={"white"}
                onClick={() => {
                  navigate("/book-a-meet");
                  onClose();
                }}
                mt={4}
              >
                Bize Ulaşın
              </Button>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};

export default Header;
